import { Injectable } from "@angular/core";
import { EntityService } from "@app/shared/services/entity.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Meeting, MeetingCreateDto, MeetingTokenDto, MeetingUpdateDto } from "@app/shared/models/classes/Meeting";
import { Observable } from "rxjs";
import { MeetingEvent } from "@app/shared/models/classes/MeetingEvent";
import { Logger } from "@app/core/logger.service";

const logger = new Logger("MeetingsService");

@Injectable()
export class MeetingsService extends EntityService<Meeting, MeetingCreateDto, MeetingUpdateDto> {
  constructor(protected httpClient: HttpClient) {
    super("meetings", "Meeting", httpClient);
  }

  getAll(
    queryParameters: string = "",
    skip: number = 0,
    take: number = 20,
    enableCache: boolean = false
  ): Observable<Meeting[]> {
    throw new Error("Not implemented. Use method 'all'");
  }

  all(code: string = null, skip: number = 0, take: number = 20, enableCache: boolean = false): Observable<Meeting[]> {
    let params = new HttpParams();
    if (code) params = params.append("code", code);
    return super.getAll(params.toString(), skip, take, enableCache);
  }

  getTokens(meetingId: string, userId: number, isRtc: boolean = null): Observable<MeetingTokenDto[]> {
    let params = new HttpParams({ fromObject: { userId } });
    if (isRtc !== null) params = params.append("rtc", isRtc.toString());
    return this.getAllChildren<MeetingTokenDto>(meetingId, "tokens", params.toString());
  }

  postMeetingEvent(code: string, meetingEvent: MeetingEvent): Observable<MeetingEvent> {
    return this.httpClient.skipErrorHandler().post<MeetingEvent>(`meetings/${code}/event`, meetingEvent);
  }
}
